import REQ from "../app/REQ";
import {collectLinked, upWFoundById} from "../utils/xyhUtils"
import {REMARK_STATE} from "@/consts"
import Options from "@/Options"
import dateFilterMix from "./dateFilterMix";

const {
	RemarkStatesSMap,
} = Options

const type = {
	topic:'topic',
	topicGrouping:'topicGrouping',
	all:'all',
}

function remarkToRow(remark, idx, subIdx) {
	let { repliedId, remarkerN, content,createDate, stateN,docN, docNum, deptN} = remark
	let typeN = repliedId?"跟进":"话题"
	return [idx + (subIdx? ("-"+subIdx):''), typeN, remarkerN, content,createDate, stateN,docN, docNum, deptN]
}

export default {
	name: "docPrjtRemarks",
	props: ['id'],
	mixins:[dateFilterMix],

	data() {
		return {
			type,

			depts:[],
			prjt: [],
			remarks:[],
			filterWord1:'',
			filterWord2:'',
			allRemarks:[],
			order:{
				createDate:false,
			},
			sortingProperty:'',
			typeForShow:type.topic,
			statusForShow:'',
			dept:'',
		};
	},

	computed: {
		displayRemarks(){
			let {filterWord2, statusForShow, typeForShow, order, dept} = this
			let remarks = this.remarks.concat()
			remarks = remarks.filter(it=>typeForShow == type.all || !it.repliedId)
			if(statusForShow) remarks = remarks.filter(it=>it.state == statusForShow)
			if(filterWord2.length>0) {
				remarks = remarks.filter(it =>
					it.remarkerN.toLowerCase().includes(filterWord2) ||
					it.content.toLowerCase().includes(filterWord2) ||
					it.docN.toLowerCase().includes(filterWord2) ||
					it.docNum.toLowerCase().includes(filterWord2) ||
					(it.remarks && it.remarks.find(item=>{
						item.remarkerN.toLowerCase().includes(filterWord2) ||
						item.content.toLowerCase().includes(filterWord2) ||
						item.docN.toLowerCase().includes(filterWord2) ||
						item.docNum.toLowerCase().includes(filterWord2)
					}))
				)
			}
			console.log(dept)
			if(dept) remarks = remarks.filter(it=>it.deptId == dept.id)
			for (let i in order) {
				let factor = order[i] ? 1 : -1
				remarks.sort((it1, it2) => factor * it1[i].toLowerCase().localeCompare(it2[i].toLowerCase()))
				if (remarks.remarks) remarks.remarks.sort((it1, it2) => factor * it1[i].toLowerCase().localeCompare(it2[i].toLowerCase()))
			}
			return remarks
		}
	},

	created() {
		this.changeDate('currentWeek')
	},

	methods: {
		getData() {
			this.req2Data(REQ.remarksInDocPrjt,{prjtId:this.id,sDate:this.sDate,eDate:this.eDate},null,({remarks, docs, staffs, depts})=>{
				upWFoundById(remarks, staffs, 'remarkerId', (remark,staff)=>remark.remarkerN = staff.name)
				remarks.forEach(it=> {
					it.createDate = new Date(parseInt(it.id.substr(0, 8), 16) * 1000).YMDHMFmt()
					if(!it.state) it.state = REMARK_STATE.normal
					it.stateN = RemarkStatesSMap[it.state] || it.state
				})
				remarks.sort((remark1,remark2)=> remark2.createDate.localeCompare(remark1.createDate))
				remarks.forEach(it=> {
					it.createDate = new Date(parseInt(it.id.substr(0, 8), 16) * 1000).MDHMFmt()
				})
				upWFoundById(docs, depts, 'deptId', (doc,dept)=>doc.deptN = dept.name)
				upWFoundById(remarks, docs, 'objId', (remark,doc)=> {
					remark.deptId = doc.deptId || ''
					remark.deptN = doc.deptN || ''
					remark.docN = doc.name
					remark.docNum = doc.num
				})
				this.remarks = collectLinked(remarks,'repliedId','remarks')
			})
		},

		setOrderProp(propertyN){
			this.sortingProperty = propertyN
			let order = !this.order[propertyN]
			delete this.order[propertyN]
			this.$set(this.order,propertyN,order)
		},

		setSearchWord() {
			this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
		},

		setFilterStatus(status){
			this.$set(this,'statusForShow',status)
		},

		docPrjtRemarksExport() {
			let {typeForShow, displayRemarks} = this
			const {export_json_to_excel} = require('@/vendor/Export2Excel');
			const tHeader = ['序号', '模式', '评论者', '评论内容', '评论时间', '状态', '对应文档', '对应文档编号', '文档归口' ];
			let data = []
			displayRemarks.forEach((remark,idx) =>{
				data.push(remarkToRow(remark, idx+1))
				if(typeForShow == type.topicGrouping && remark.remarks) remark.remarks.forEach((r2, i2)=> data.push(remarkToRow(r2, idx+1, i2+1)) )
			})
			export_json_to_excel(tHeader, data, `${this.prjt.name}项目文档评论${this.sDate}~${this.eDate}`);
		},

		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		},

		deptFilter(dept){
			this.$set(this,'dept',dept)
		},

		setFilterType(type){
			this.$set(this,'typeForShow',type)
		},
	},
};
